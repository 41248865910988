import { Form, Card, Button, Row, Col, Table, Alert, Image, Collapse, OverlayTrigger, Popover, Container, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState, useMemo } from 'react';
import { setIsLoading } from '../general/actions';
import {currencyFormater} from '../general/utils';
import AlertModal from '../general/alertModal';
import { getImage, HOST, DEFAULT_IMG } from '../general/utils';
import SelectAgreement from '../general/selectAgreement';

function Products(props) {
    const { setIsLoading } = props;

    const [productTypes, setProductTypes] = useState([]);
    const [products, setProducts] = useState([]);
    const [isCreation, setIsCreation] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [agreementFilter, setAgreementFilter] = useState(0);

    const [openBulkUpload, setOpenBulkUpload] = useState(false);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertImgSrc, setAlertImgSrc] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    const [alertModalOnConfirm, setAlertModalOnConfirm] = useState(null);
    const [alertModalOnClose, setAlertModalOnClose] = useState(null);

    const openImg = async (e) => {
        setAlertModalShow(true);
        setAlertImgSrc(e.target.src);
        setAlertModalMsg('');
        setAlertModalType('');
    }

    useEffect(()=>{
        if(alertModalMsg) {
            setAlertImgSrc('');
        }
    }, [alertModalMsg]);

    const getProductTypes = () => {
        setIsLoading(true);
        axiosInstance.get('/product-types')
            .then(res => {
                setProductTypes(res.data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setAlertModalType('danger');
                setAlertModalMsg(error.response.data.message)
                console.log(error);
            });
    }

    // Product fields
    const [idEdit, setIdEdit] = useState('');
    const [agreement, setAgreement] = useState('');
    const [extId, setExtId] = useState('');
    const [product, setProduct] = useState('');
    const [isVisible, setIsVisible] = useState('');
    const [description, setDescription] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [imgUrlNew, setImgUrlNew] = useState('');
    const [price, setPrice] = useState(0);
    const [taxes, setTaxes] = useState(0);
    const pricePlus = useMemo(() => {
        if (!taxes) {
            return  price;
        }

        if (!price) {
            return  0;
        }

        return parseFloat(price) * ((parseFloat(taxes) / 100) + 1);
    }, [price, taxes]);
    const [productType, setProductType] = useState('');

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
        setImgUrl(DEFAULT_IMG);
    };

    const uploadImage = () => {
        const formData = new FormData();
        formData.append('image', selectedImage);

        return axiosInstance.post('/upload-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    useEffect(() => {
        setMessageType('warning');
        if (product == '') {
            return setMessage('Produto es obligatorio');
        } else if (!agreement) {
            return setMessage('Contrato es obligatorio');
        } else if (isNaN(price) || price <= 0) {
            return setMessage('Precio inválido');
        } else if (isNaN(taxes) || taxes < 0) {
            return setMessage('IVA/Impuesto inválido');
        } else {
            setMessage('');
        }
    }, [product, agreement, price, taxes]);

    const setEditData = (row) => {
        setIdEdit(row.id);
        setProduct(row.name);
        setAgreement(row.agreement_id);
        setDescription(row.description);
        setExtId(row.ext_id);
        setIsVisible(row.is_visible);
        setImgUrl(row.img_url);
        setPrice(parseFloat(row.price));
        setTaxes(row.taxes);
        setProductType(row.product_type_id);
        setSelectedImage(null);
    }

    // Clear the form when is a creation
    useEffect(() => {
        if (isCreation) {
            setProduct('');
            setAgreement(0);
            setDescription('');
            setIsVisible(true);
            setExtId('');
            setIdEdit(false);
            setImgUrl('');
            setPrice(0);
            setTaxes(0);
            setProductType('');
            setImgUrlNew('');
            setSelectedImage(null);
        }
    }, [isCreation]);

    const getProducts = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`/products/agreements/${agreementFilter}`);

            setProducts(response.data);
            if (!response.data.length) {
                setMessageType('warning');
                setMessage('El contrato que seleccionaste no tiene productos asociados');
            } else {
                setMessage('');
            }

            if (!productTypes.length) {
                getProductTypes();
            }
            setIdEdit(0);
        } catch (error) {
            setAlertModalType('danger');
            setAlertModalMsg(error.response.data.message)
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const saveProduct = (imageUrl) => {
        if (isCreation) {
            createProduct(imageUrl);
        } else {
            updateProduct(imageUrl);
        }
    }

    const submitProduct = () => {
        setIsLoading(true);

        if (selectedImage) {
            uploadImage()
                .then((response) => {
                    console.log(response.data);

                    setImgUrlNew(response.data.image_url);
                    saveProduct(response.data.image_url);
                })
                .catch((error) => {
                    setAlertModalType('danger');
                    setAlertModalMsg(error.response.data.message)
                    console.log(error);
                })
        } else {
            saveProduct();
        }
    }

    const getProductData = (imageUrl) => {
        return {
            ext_id: extId,
            name: product,
            agreement_id: agreement,
            description: description,
            price: price,
            taxes: taxes,
            img_url: imageUrl,
            is_visible: isVisible,
            product_type_id: productType,
        }
    }

    const createProduct = async (imageUrl) => {
        try {
            const response = await axiosInstance.post(`/products`, getProductData(imageUrl));
            setIsCreation(false);
            getProducts();
            console.log(response.data);
        } catch (error) {
            setMessageType('danger');
            if (error.response.data.message.indexOf('Duplicate') > 0) {
                setMessage('Tipo de produto ya existe');
            } else {
                setMessage(error.message);
            }
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const updateProduct = async (imageUrl) => {
        try {
            const response = await axiosInstance.put(`/products/${idEdit}`, getProductData(imageUrl));
            setIdEdit(0);
            getProducts();
            console.log(response.data);
        } catch (error) {
            setMessageType('danger');
            if (error.response.data.message.indexOf('Duplicate') > 0) {
                setMessage('Produto ya existe');
            } else {
                setMessage(error.message);
            }
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const deleteProduct = async (id, confirmed) => {
        if (!confirmed) {
            setAlertModalShow(true);
            setAlertModalType('');
            setAlertModalMsg('¿Esta seguro de eliminar el producto ' + id + '?');
            setAlertModalOnConfirm(()=>()=>{deleteProduct(id, true)});
            return;
        }

        setIsLoading(true);
        try {
            const response = await axiosInstance.delete(`/products/${id}`);

            setAlertModalShow(true);
            setAlertModalMsg('Producto ' + id + ' eliminado correctamente');
            setAlertModalOnClose(()=>()=>{setIdEdit(null); setIsCreation(false);});

            getProducts();
            console.log(response.data);
        } catch (error) {
            setAlertModalShow(true);
            setAlertModalType('danger');

            if (error.response.data.message.includes('order_details')) {
                setAlertModalMsg("El producto no se puede elimar porque tiene pedidos asociados");
            } else {
                setAlertModalMsg(error.response.data.message);
            }

            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const bulkUpload = async (e) => {

        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        e.target.value = null;

        try {
            const response = await axiosInstance.post(`/products/import-csv`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            getProducts();
            console.log(response.data);
        } catch (error) {
            setAlertModalShow(true);
            setAlertModalType('danger');

            let msg = 'No se creo ningún producto: ';

            if (error.response.data.message.includes('Duplicate')) {
                setAlertModalMsg(msg + 'Hay un producto en el archivo que ya existe en el sistema, por favor verifique.');
            } else if(error.response.data.message.includes('Unexpected')) {
                setAlertModalMsg(msg + 'Contenido de el archivo inválido')
            } else if(error.response.data.message.includes('agreement')) {
                setAlertModalMsg(msg + 'Hay un contrato inválido en el archivo, por favor verifique.')
            } else {
                setAlertModalMsg(msg + error.response.data.message)
            }
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        if (agreementFilter) {
            getProducts();
        } else {
            setMessage('Seleciona un contrato para ver los productos');
        }
    }, [agreementFilter]);

    return <>
        {(!idEdit && !isCreation) &&
            <>
                <Row>
                    <Col md={1} xs={12}> <legend>Productos</legend></Col>
                    <Col md={4} xs={12}>
                        <SelectAgreement agreement={agreementFilter} setAgreement={setAgreementFilter} disabled={false}/>
                    </Col>
                    <Col md={3} xs={12}>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder='Buscar por producto o descripción'
                                type="text"
                                value={searchText}
                                onChange={(e) => { e.preventDefault(); setSearchText(e.target.value); }}
                            />
                            <Button variant="outline-dark" onClick={() => setSearchText('')}>
                                Limpiar
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col md={4} xs={6} align="end">
                        {agreementFilter &&
                        <Button variant="dark" title="Seleccione un contrato" onClick={() => setIsCreation(true)}>
                            Crear Producto
                        </Button>
                        }
                        {' '}
                        <Button variant="outline-dark"
                            onClick={() => setOpenBulkUpload(!openBulkUpload)}
                            aria-controls="bulk-upload"
                            aria-expanded={openBulkUpload}
                        >
                            Creacion Masiva de Productos
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xs={12}>
                        <Collapse in={openBulkUpload}>
                            <div id="bulk-upload">
                                <hr />
                                Para la creación masiva de productos descargue la siguiente plantilla {' '}
                                <a target="_blank" href={HOST + 'storage/files/products.csv'}>Descargar Plantilla</a>  {', '}
                                diligencie el archivo conservando el formato csv y seleccione el archivo:
                                <br />
                                <br />
                                <Col md={6} xs={12}>
                                    <Form.Group controlId="formFile" className="mb-3" onChange={(e) => bulkUpload(e)} accept=".csv">
                                        <Form.Control type="file" />
                                    </Form.Group>
                                </Col>
                            </div>
                        </Collapse>
                    </Col>
                </Row>
                <hr />
                <br />
                <Table responsive>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Contrato</th>
                            <th>Id</th>
                            <th>Ext Id</th>
                            <th>Producto</th>
                            <th>Descripción</th>
                            <th>Precio (Iva Incluido)</th>
                            <th>IVA/Impuesto</th>
                            <th>Tipo</th>
                            <th>Visible</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.length ? products.filter((item) => {
                            return item.name.toLowerCase().includes(searchText.toLowerCase()) ||
                            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
                            String(item.ext_id).includes(searchText.toLowerCase())
                            ;
                        }).map(item => (
                            <tr key={item.id}>
                                <td>
                                    <Image onClick={(e) => openImg(e)}
                                        className="pointer"
                                        width="40rem" rounded
                                        src={getImage(item.img_url)}
                                    />
                                </td>
                                <td>{item.agreement_id}</td>
                                <td>{item.id}</td>
                                <td>{item.ext_id}</td>
                                <td>{item.name}</td>
                                <td>
                                    <OverlayTrigger placement="bottom"
                                        overlay={
                                            <Popover id="tooltip-disabled" >
                                                <Container>{item.description}</Container>
                                            </Popover >
                                        }>
                                        <span className="d-inline-block">
                                            {item.description && item.description.slice(0, 125)}
                                            {item.description && item.description.length > 125 ? '...' : ''}
                                        </span>
                                    </OverlayTrigger>
                                </td>
                                <td>
                                    {currencyFormater(Math.round(parseFloat(item.price) * ((parseFloat(item.taxes) / 100) + 1)))}
                                </td>
                                <td>{item.taxes}%</td>
                                <td>{item.product_type_id}{': '}{item.product_type.name}</td>
                                <td>{item.is_visible ? 'Si' : 'No'}</td>
                                <td>
                                    <Button variant="dark" onClick={(e) => setEditData(item)}>Editar</Button>
                                </td>
                                <td>
                                    <Button variant="outline-dark" onClick={(e) => deleteProduct(item.id)}>Eliminar</Button>
                                </td>
                            </tr>
                        )) : <tr><td></td></tr>}
                    </tbody>
                </Table>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </>
        }
        {(idEdit || isCreation) &&
            <>
                <Row>
                    <Col md={8} xs={12}>

                        <legend>
                            {idEdit ?
                                'Modificar Producto ' + idEdit + ': ' + product
                                :
                                'Crear Producto'
                            }
                        </legend>
                    </Col>
                    <Col md={4} xs={12} align="end">
                        {idEdit &&
                            <Button variant="outline-dark" onClick={(e) => deleteProduct(idEdit)}>Eliminar</Button>
                        }
                    </Col>
                </Row>
                <hr />
                <br />
                <Form >
                    <Row>
                    <Col md={8} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Contrato: </Form.Label>
                                    <SelectAgreement agreement={agreement} setAgreement={setAgreement} disabled={false}/>
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} xs={12}>

                            <Form.Group className="mb-3">
                                <Form.Label>Ext Id</Form.Label>
                                <Form.Control type="text" placeholder="Id Externo/SKU/Serial" value={extId} onChange={(e) => setExtId(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col md={4} xs={12}>

                            <Form.Group className="mb-3">
                                <Form.Label>Producto</Form.Label>
                                <Form.Control type="text" placeholder="" value={product} onChange={(e) => setProduct(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col md={4} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Tipo de Producto</Form.Label>
                                <Form.Select value={productType} onChange={(e) => setProductType(e.target.value)}>
                                    <option value={''} >
                                            Seleccione un tipo de producto
                                        </option>
                                    {productTypes.map(productType => (

                                        <option
                                         key={productType.id} 
                                         value={productType.id} 
                                         disabled={!productType.is_visible}
                                         >
                                            {productType.id}: {productType.name} {!productType.is_visible ? '----Inactivo----' : ''}
                                        </option>

                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col md={4} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Precio (Antes de IVA): {currencyFormater(price)}</Form.Label>
                                <Form.Control type="number" placeholder="" value={parseFloat(price)} onChange={(e) => setPrice(e.target.value)} />
                            </Form.Group>

                        </Col>
                        <Col md={4} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Porcentaje IVA/Impuesto</Form.Label>
                                <Form.Control type="number" placeholder="" value={taxes} onChange={(e) => setTaxes(e.target.value)} />
                            </Form.Group>

                        </Col>
                        <Col md={4} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Precio + IVA:</Form.Label>
                                <Form.Control type="text" placeholder="" value={currencyFormater(Math.round((pricePlus)))} disabled/>
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} xs={12}>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control as="textarea" rows={6} value={description} onChange={(e) => setDescription(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col md={4} xs={12}>
                            <Form.Group  as={Card} controlId="formFile" onChange={handleImageChange} accept="image/*">
                                <Card.Title as={Container}><h5>Imagen del producto</h5></Card.Title>
                                <Card.Text as={Container} align='center'>
                                    <Image
                                        onClick={(e) => openImg(e)}
                                        width="200rem" src={getImage(imgUrl)}
                                        rounded
                                    />
                                    {selectedImage ? <>
                                        <br></br>
                                        <Alert>La imagen se enviara al guardar el producto</Alert>
                                    </>
                                    :''}
                                </Card.Text>
                                <Form.Control type="file" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} xs={12}>
                            <Form.Group className="mb-3" controlId="visible">
                                <Form.Check type="checkbox" label="Visible para pedidos nuevos" checked={isVisible} onChange={(e) => setIsVisible(e.target.checked)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {message &&
                        <Alert variant={messageType || 'warning'}>
                            {message}
                        </Alert>
                    }
                    <Row  align='end'>
                        <Col md={12} xs={6}>
                            <Button variant="dark" onClick={submitProduct} disabled={!!message}>
                                Guardar
                            </Button>
                            {' '}
                            <Button variant="outline-dark" onClick={(e) => { setIdEdit(false); setIsCreation(false); }}>
                                Volver
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                    </Row>
                </Form>
            </>
        }
        <AlertModal 
            show={alertModalShow} setShow={setAlertModalShow}
            msg={alertModalMsg}  setMsg={setAlertModalMsg}
            onConfirm={alertModalOnConfirm} setOnConfirm={setAlertModalOnConfirm}
            onClose={alertModalOnClose} setOnClose={setAlertModalOnClose} 
            imgSrc={alertImgSrc}
            variant={alertModalType}
        />
    </>;
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);