import { Form, InputGroup, Button, Row, Col, Table, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { setIsLoading } from '../general/actions';
import { currencyFormater, downloadExcel } from '../general/utils';
import SelectAgreement from '../general/selectAgreement'

function FundsReport(props) {
    const { setIsLoading, isLoading } = props;

    const [funds, setFunds] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [agreementFilter, setAgreementFilter] = useState(0);

    const [amountTotal, setAmountTotal] = useState(0);
    const [spentTotal, setSpentTotal] = useState(0);
    const [availableTotal, setAvailableTotal] = useState(0);

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    useEffect(() => {
        if (agreementFilter) {
            setSearchText('');
            getFunds();
        } else {
            setFunds([]);
            setMessageType('info');
            setMessage('Seleciona un contrato para ver los fondos');
        }
    }, [agreementFilter]);

    useEffect(() => {
        setAmountTotal(funds.reduce((acc, item) => acc + parseFloat(item.amount), 0));
        setSpentTotal(funds.reduce((acc, item) => acc + parseFloat(item.spent), 0));
        setAvailableTotal(funds.reduce((acc, item) => acc + parseFloat(item.available), 0));
    }, [funds]);

    const getFunds = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/funds/agreements/' + agreementFilter);
            console.log(response.data);

            setFunds(response.data);

            if (response.data.length) {
                setMessage('');
            } else {
                setMessageType('info');
                setMessage('El contrato seleccionado no tiene fondos asociados');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>

            <Col md={12} xs={12}>
                <Row>
                    <Col md={12} xs={12}> <legend>Reporte de Fondos</legend></Col>
                    <Col md={8} xs={12}>
                    <SelectAgreement agreement={agreementFilter} setAgreement={setAgreementFilter} disabled={false}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder='Filtrar por fondo o descripción'
                                type="text"
                                value={searchText}
                                disabled={!funds.length}
                                onChange={(e) => { e.preventDefault(); setSearchText(e.target.value); }}
                            />
                            <Button variant="outline-dark" onClick={() => setSearchText('')}>
                                Limpiar
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col md={2} xs={6} align="end">

                    </Col>
                </Row>
                <hr />
                <br />
                {funds.length ? <>
                <div align="end">
                    <Button variant="success" onClick={() => { 
                        setIsLoading(true);
                        downloadExcel('funds', 'Reporte de fondos', ()=> setIsLoading(false));
                    }}>
                        <i className='fa fa-file' /> Descargar
                    </Button>
                    {' '}
                    <Button variant="primary" title='Actualizar Resultados' onClick={() => getFunds()}>
                        <i className='fa fa-rotate-right' />
                    </Button>
                </div>
                <Table responsive className="table table-hover" id='funds'>
                    <thead>
                        <tr >
                            <th>Centro de Costos</th>
                            <th colSpan={2}>Fondo</th>
                            <th>Valor Inicial</th>
                            <th>Consumo</th>
                            <th>Disponible</th>
                            <th>Activo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {funds.filter((item) => {
                            return (
                                (item && item.ext_id && item.ext_id.toLowerCase().includes(searchText.toLowerCase())) ||
                                (item && item.name && item.name.toLowerCase().includes(searchText.toLowerCase()))
                            );
                        }).map(item => (
                            <tr key={'funds' + item.id}>
                                <td>{item.department.id}{':'}{item.department.name}</td>
                                <td>{item.id}:{item.ext_id}</td>
                                <td>{item.name}</td>
                                <td>
                                    {isLoading ? item.amount : currencyFormater(item.amount)}
                                    <br></br>
                                    <progress
                                        value={(item.spent / item.amount) || 0}
                                        max={1} />
                                </td>
                                <td>
                                    {isLoading ? item.spent : currencyFormater(item.spent)}
                                </td>
                                <td>
                                    {isLoading ? item.available : currencyFormater(item.available)}
                                </td>
                                <td>{item.is_active ? 'Si' : 'No'}</td>
                            </tr>
                        ))}
                    </tbody>
                    {!searchText ? 
                    <tfoot>
                        <tr className="table-dark">
                            <th colSpan={3}>Total:</th>
                            <th>
                                {isLoading ? amountTotal : currencyFormater(amountTotal)}
                                <br></br>
                                <progress
                                    value={(spentTotal / amountTotal) || 0}
                                    max={1} />
                            </th>
                            <th>{isLoading ? spentTotal : currencyFormater(spentTotal)}<br></br><br></br></th>
                            <th>{isLoading ? availableTotal : currencyFormater(availableTotal)}<br></br><br></br></th>
                            <th colSpan={2}></th>
                        </tr>
                    </tfoot>
                    : ''}
                </Table>
                </>
                : ''}
                {message &&
                    <Alert variant={messageType || 'warning'}>
                        {message}
                    </Alert>
                }
            </Col>

        </>)
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(FundsReport);