import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Alert, Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { setIsLoading, login } from '../general/actions';
import ResetPassword from './resetPassword';
import axiosInstance from '../general/axiosInstance';
import AlertModal from '../general/alertModal';
import axios from 'axios';

import Funds from './funds';

function ManageUser(props) {
    const { setIsLoading, isCreatigUser, login, is_provider } = props;

    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    const navigate = useNavigate();

    // Funds Modal
    const [fundsShow, setFundsShow] = useState(false);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');

    const [showResetPassword, setShowResetPassword] = useState(isCreatigUser ? false : true);

    const [password, setPassword] = useState('');

    function handleSetPasswordConfirmed(value) {
        setPassword(value);
    }

    useEffect(() => {
        if (!isCreatigUser) {
            getUser();
        }
    }, []);

    // Validate fields
    useEffect(() => {
        if (!name || !phone || !email) {
            setMessageType('warning');
            setMessage('Todos los campos son obligatorios');
        } else if (!isValidateEmail(email)) {
            setMessageType('warning');
            setMessage('Email no válido');
        } else {
            setMessage('');
        }
    }, [name, email, phone]);

    function isValidateEmail(value) {
        // Validar el correo electrónico aquí utilizando una expresión regular
        const regex = /^\S+@\S+\.\S+$/;
        return regex.test(value);
    }

    const getUser = function () {
        setIsLoading(true);
        setShowResetPassword(true);
        axiosInstance.get(`/user`)
            .then(response => {
                setName(response.data.name);
                setEmail(response.data.email);
                setPhone(response.data.phone);

                let departments = [];
                response.data.user_department.forEach(rel => {
                    departments.push(rel.department.id + ': '+  rel.department.name);  
                });

                setDepartments(departments);


                setRoles(response.data.user_role);

                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);

                setAlertModalShow(true);
                if (error.code == 'ERR_NETWORK') {
                    setAlertModalMsg('No hay conexión a internet');
                } else {
                    setAlertModalMsg('Error desconocido: ' + error.message);
                }

            });
    }

    const updateUser = async () => {
        const editUser = { name, email, phone };
        setIsLoading(true);

        try {
            axiosInstance.put(`/users`, editUser)
                .then(response => {
                    setMessageType('success');
                    setMessage(response.data.message);
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);

                    setAlertModalShow(true);
                    if (error.response.data.message.indexOf('Duplicate') > 0) {
                        setAlertModalMsg('El email ya esta en uso por otro usuario');
                    } else {
                        setMessage(error.message);
                    }
                    console.log(error);
                });
        } catch (error) {
            console.error(error);
        }
    };

    const saveUser = async (event) => {
        event.preventDefault();

        if (isCreatigUser) {
            createUser();
        } else {
            updateUser();
        }
    }

    const createUser = async () => {
        let password_confirmation = password;
        const createUser = { name, email, phone, password, password_confirmation };
        setIsLoading(true);

        try {
            axios.post(`/register`, createUser)
                .then(response => {
                    setIsLoading(false);

                    login(response.data.user.name);

                    localStorage.setItem('token', response.data.token);

                    navigate('/main');
                })
                .catch(error => {
                    setIsLoading(false);
                    setAlertModalShow(true);

                    if (error.response.data.message.indexOf('email') > 0) {
                        setAlertModalMsg('El email ya esta en uso por otro usuario');
                    } else {
                        setAlertModalMsg(error.message);
                    }
                });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <>
                <Form onSubmit={saveUser} >
                    <Row>
                        <Col>
                            <legend>{isCreatigUser ? 'Registrar ' : 'Información '} Usuario: {name}</legend>
                        </Col>
                        {!isCreatigUser && !is_provider ?
                        <Col xs={12} md={4} align='end'>
                            <Button variant={'dark'} onClick={()=>setFundsShow(true)}>
                                <i className="fa fa-money" />
                                {' '}Ver mis Fondos
                            </Button>
                        </Col>
                        :''}
                    </Row>
                    <hr/>
                    <br/>
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" placeholder="Nombre completo" value={name} onChange={(e) => setName(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={!isCreatigUser}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Teléfono</Form.Label>
                                <Form.Control type="text" placeholder="Teléfono o celular" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {(!isCreatigUser && !is_provider)  &&
                    <Row>
                        <Col xs={12} md={8}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Centros de costo:</Form.Label>
                                <h4>
                                {(departments.length) ? departments.map(dep => (
                                        <span key={'dep'+dep}>
                                            <Badge bg="secondary">{dep}</Badge>{' '}
                                        </span>
                                )): ''}
                                </h4>
                            </Form.Group>
                        </Col>
                    </Row>
                    }
                    {(!isCreatigUser)  && 
                    <Row>
                        <Col xs={12} md={8}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Roles asignados:</Form.Label>
                                <h4>
                                 {(roles.length) ? roles.map(rol => (
                                        <span key={'dep'+rol.id}>
                                            <Badge bg="secondary">{rol.role.id}: {rol.role.description}</Badge>{' '}
                                        </span>
                                )): ''}
                                </h4>
                            </Form.Group>
                        </Col>
                    </Row>
                    }
                    {message &&
                        <Row >
                            <Col xs={12} md={12}>
                                <Alert variant={messageType}>
                                    {message}
                                </Alert>
                            </Col>
                        </Row>
                    }
                    {!isCreatigUser &&
                        <Row align='end'>
                            <Col xs={12} md={12}>
                                <Button variant="dark" type="submit" disabled={!!message}>
                                    Guardar
                                </Button>
                                {' '}
                                <Button  as={Link} to="/main" variant="outline-dark" >
                                    Cancelar
                                </Button>
                            </Col>
                        </Row>
                    }
                </Form>
            </>
            <ResetPassword 
                isCreatigUser={isCreatigUser}
                show={showResetPassword}
                setMessage={setMessage}
                passwordConfirmed={handleSetPasswordConfirmed} 
            />
            {isCreatigUser &&
                <Row align='end'>
                    <Col xs={12} md={12}>
                        <Button variant="dark" onClick={saveUser} disabled={!!message || !password}>
                            Registrame
                        </Button>
                        {' '}
                        <Button as={Link} to="/home" variant="outline-dark" >Cancelar </Button>
                    </Col>
                </Row>
            }
            <Funds show={fundsShow} setShow={setFundsShow} />
            <AlertModal show={alertModalShow} setShow={setAlertModalShow} msg={alertModalMsg} />
        </>
    );
}

function mapStateToProps(state) {
    return {
        username: state.username,
        isLoggedIn: state.isLoggedIn,
        isLoading: state.isLoading,
        is_provider: state.is_provider,
    };
}

const mapDispatchToProps = {
    setIsLoading,
    login
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);