import { Form, Button, Row, Col, Table, Alert, InputGroup, Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { setIsLoading } from '../general/actions';
import { currencyFormater } from '../general/utils'
import AlertModal from '../general/alertModal';
import { HOST } from '../general/utils';
import SelectAgreement from '../general/selectAgreement';

function FundsList(props) {
    const { setIsLoading, agreement } = props;

    const [items, setItems] = useState([]);
    const [isCreation, setIsCreation] = useState(props.isCreation);
    const [idEdit, setIdEdit] = useState(props.idEdit);

    const [searchText, setSearchText] = useState('');
    const [agreementFilter, setAgreementFilter] = useState(agreement || '');

    const [openBulkUpload, setOpenBulkUpload] = useState(false);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    useEffect(()=>{
        props.setIsCreation(isCreation);
    }, [isCreation]);

    const getList = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`/funds/agreements/${agreementFilter}`);

            setItems(response.data);
            if (!response.data.length) {
                setMessage('');

            } else {
                setMessage('');
            }

        } catch (error) {
            setAlertModalShow(true);
            setAlertModalType('danger');
            setAlertModalMsg(error.response.data.message)
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const bulkUpload = async (e) => {

        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        e.target.value = null;

        try {
            const response = await axiosInstance.post(`/funds/import-csv`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            getList();

            setAlertModalShow(true);
            setAlertModalType('success');
            setAlertModalMsg('Fondos creados correctamente')

            console.log(response.data);
        } catch (error) {
            setAlertModalShow(true);
            setAlertModalType('danger');

            let msg = 'No se creo ningún fondo: ';

            if (error.response.data.message.includes('Duplicate')) {
                setAlertModalMsg(msg + 'Hay un fondo en el archivo que ya existe en el sistema, por favor verifique.');
            } else if(error.response.data.message.includes('Unexpected')) {
                setAlertModalMsg(msg + 'Contenido de el archivo inválido')
            } else if(error.response.data.message.includes('agreement')) {
                setAlertModalMsg(msg + 'Hay un contrato inválido en el archivo, por favor verifique.')
            } else {
                setAlertModalMsg(msg + error.response.data.message)
            }
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        if (agreementFilter) {
            getList();
            props.setAgreement(agreementFilter);
        } else {
            setItems([]);
            setMessage('Seleciona un contrato para ver los fondos');
        }
    }, [agreementFilter]);

    return <>
        {(!idEdit && !isCreation) &&
            <>
                <Row>
                    <Col md={1} xs={12}> <legend>Fondos</legend></Col>
                    <Col md={4} xs={12}>
                    <SelectAgreement agreement={agreementFilter} setAgreement={setAgreementFilter} disabled={false}/>
                    </Col>
                    <Col md={3} xs={12}>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder='Buscar por fondo o descripción'
                                type="text"
                                value={searchText}
                                onChange={(e) => { e.preventDefault(); setSearchText(e.target.value); }}
                            />
                            <Button variant="outline-dark" onClick={() => setSearchText('')}>
                                Limpiar
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col md={4} xs={12} align="end">
                        { agreementFilter &&
                        <Button variant="dark" onClick={() => setIsCreation(true)} disabled={!agreementFilter}>
                            Crear Fondo
                        </Button>
                        }
                        {' '}
                        <Button variant="outline-dark"
                            onClick={() => setOpenBulkUpload(!openBulkUpload)}
                            aria-controls="bulk-upload"
                            aria-expanded={openBulkUpload}
                        >
                            Creacion Masiva de Fondos
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xs={12}>
                        <Collapse in={openBulkUpload}>
                            <div id="bulk-upload">
                                <hr />
                                Para la creación masiva de fondos descargue la siguiente plantilla {' '}
                                <a target="_blank" href={HOST + 'storage/files/funds.csv'}>Descargar Plantilla</a>  {', '}
                                diligencie el archivo conservando el formato csv y seleccione el archivo:
                                <br />
                                <br />
                                <Col md={6} xs={12}>
                                    <Form.Group controlId="formFile" className="mb-3" onChange={(e) => bulkUpload(e)} accept=".csv">
                                        <Form.Control type="file" />
                                    </Form.Group>
                                </Col>
                            </div>
                        </Collapse>
                    </Col>
                </Row>
                <hr />
                <br />
                <Table responsive>
                    <thead>
                        <tr >
                            <th>Contrato</th>
                            <th>Id Fondo</th>
                            <th>Ext Id</th>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th>Valor Inicial</th>
                            <th>Consumo</th>
                            <th>Disponible</th>
                            <th>Centro de costos</th>
                            <th>Activo</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(items.length) ? items.filter((item) => {
                            return item.name && item.name.toLowerCase().includes(searchText.toLowerCase()) 
                            || item.description && item.description.toLowerCase().includes(searchText.toLowerCase())
                            || item.ext_id && String(item.ext_id).includes(searchText.toLowerCase())
                            ;
                        }).map(item => (
                            <React.Fragment key={'fund' + item.id}>
                            <tr >
                                <td>{item.agreement_id}</td>
                                <td>{item.id}</td>
                                <td>{item.ext_id}</td>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                                
                                <td>{currencyFormater(item.amount)}</td>
                                <td>{currencyFormater(item.spent)}</td>
                                <td>{currencyFormater(item.available)}</td>
                                <td>{item.department && item.department.name ? item.department.id + ': ' + item.department.name : ''}</td>
                                <td>{item.is_active ? 'Si' : 'No'}</td>
                                <td>
                                    <Button variant="dark" onClick={(e) => props.setEditData(item)}>Editar/Asignar</Button>
                                </td>
                            </tr>
                           {item.user_fund && item.user_fund[0] && item.user_fund[0].user && item.user_fund[0].user.id  && item.user_fund.map((userFund) => (
                            <tr key={'userFund' + userFund.user.id} class="bg-light">
                                <td></td>
                                <td>Asignado:</td>
                                <td colSpan={9}>{userFund.user.id}: {userFund.user.name} {userFund.user.email}</td>
                            </tr>
                          ))}
                          </React.Fragment>
                        )) : <tr><td></td></tr>}
                    </tbody>
                </Table>
            </>
        }
        {message &&
            <Alert variant={messageType || 'warning'}>
                {message}
            </Alert>
        }
         <AlertModal 
            show={alertModalShow} setShow={setAlertModalShow}
            msg={alertModalMsg} setMsg={setAlertModalMsg}
            variant={alertModalType}
        />
    </>;
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(FundsList);