import ManageUser from "./manage";

const Register = () => {

    return (
        <ManageUser isCreatigUser="true"/>
    );
};

export default Register;
